/* eslint-disable react/prop-types */
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

function ProgressBar({ time }) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    // Convert the given Unix time to a moment object
    const endTime = moment.unix(time);

    // Calculate the start time, which is 72 hours before the end time
    const startTime = endTime.clone().subtract(72, "hours");

    const updateProgress = () => {
      const currentTime = moment();

      if (currentTime.isSameOrAfter(endTime)) {
        // If the current time is after or equal to the end time, set progress to 100%
        setProgress(100);
        clearInterval(intervalId);
      } else {
        // Calculate the time elapsed since the start time
        const timeElapsed = currentTime.diff(startTime);

        // Total time is 72 hours in milliseconds
        const totalTime = endTime.diff(startTime);

        // Scale the percentage to start at 50% and end at 100%
        const percentage = 50 + Math.min((timeElapsed / totalTime) * 50, 50);
        setProgress(percentage);
      }
    };

    // Update the progress bar every second
    const intervalId = setInterval(updateProgress, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [time]);
  return (
    <Stack
      className="borderImgTwo"
      sx={{
        height: "25px",
        backgroundColor: "#D9D9D90D",
        mt: 0.5,
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <Stack
        height={"100%"}
        alignItems={"start"}
        justifyContent={"center"}
        position={"relative"}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: `${progress}%`,
            borderRadius: "20px",
            background:
              "linear-gradient(270deg, #00FF00 0%, rgba(0, 255, 0, 0) 100%);",
            zIndex: 0,
          }}
        />
        <Typography
          variant="subtitle2"
          sx={{
            color: "#ffff",
            fontSize: "0.875rem",
            fontWeight: "500",
            zIndex: 1,
            // pl: 1.2,
            textAlign: "center",
            fontFamily: "Montserrat",
            width: "100%",
            textTransform: "uppercase",
          }}
        >
          Until price increase
        </Typography>
      </Stack>
    </Stack>
  );
}

export default ProgressBar;
