import {
  Box,
  Divider,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  bnbLogo,
  usdtLogo,
  cardLogo,
  inputCenterLogo,
  tokenLogo,
  ethIcon,
} from "./SmallComponents/Images";
import TimerCountDown from "./SmallComponents/Timer";
import {
  fixedDecimal,
  StyledButtonTwo,
  StyledInput,
  ToastNotify,
} from "./SmallComponents/AppComponents";
import { AppContext } from "../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  newBscPresaleAddress,
  newEthPresaleAddress,
} from "../ConnectivityAssets/environment";
import presaleAbi from "../ConnectivityAssets/newBscPresaleAbi.json";
import {
  bscChainId,
  ethChainId,
  newPreSaleReadFunction,
  newPreSaleWriteFunction,
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { encodeFunctionData, formatUnits, parseEther, parseUnits } from "viem";
import Loading from "./SmallComponents/loading";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer/";
import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import CommingSoon from "./SmallComponents/CommingSoon";
import { useSwitchChain } from "wagmi";
import ProgressBar from "./SmallComponents/ProgressBar";

function Presale({ getUserTokenBalance }) {
  window.Buffer = Buffer;
  const matches = useMediaQuery("(max-width:650px)");
  const { switchChain } = useSwitchChain();
  const { account, chainId, userPurchasedTokens } = useContext(AppContext);
  const { open } = useWeb3Modal();
  const [inputSrc, setInputSrc] = useState("");
  const [buyWith, setBuyWith] = useState("BNB");
  const [amount, setAmount] = useState("");
  const [commingSoonDilouge, setCommingSoonDilouge] = useState(false);
  const [receiveToken, setReceiveToken] = useState("");
  const [tokenPerEth, setTokenPerEth] = useState(0);
  const [tokenPerUSDT, setTokenPerUSDT] = useState(0);
  const [totalSoldTokens, settotalSoldTokens] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [loading, setloading] = useState(false);
  const [timeLastUpdate, setTimeLastUpdate] = useState(0);
  const [receiveTokenStatus, setReceiveTokenStatus] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  /// wert configration start here
  useEffect(() => {
    if (account) {
      (async () => {
        try {
          let amountToBuy = amount && +amount > 0 ? +amount : 1;
          const sc_input_data = encodeFunctionData({
            abi: presaleAbi,
            functionName: "buyTokenCard",
            args: [
              account,
              parseUnits(receiveToken?.toString(), 18)?.toString(),
            ],
            value: parseUnits(amountToBuy.toString(), 8).toString(),
          });
          setInputSrc(sc_input_data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [account, amount, receiveToken]);

  const privateKey =
    "0x1c16a952222e50ed79c078f4455454204c2b30eb3c384d08743a3f56afc22ac7";

  const signedData = signSmartContractData(
    {
      address: account,
      commodity: "BNB",
      network: "bsc",
      commodity_amount: amount ? parseFloat(amount) : 0,
      sc_address: newBscPresaleAddress,
      sc_input_data: inputSrc,
    },
    privateKey
  );
  const otherWidgetOptions = {
    partner_id: "01HRP8FP2160RRN6WV3TAQXMZH",
    widgetLayoutMode: "Modal",
    click_id: uuidv4(), // unique id of purhase in your system
    origin: "https://widget.wert.io", // this option needed only for this example to work
    extra: {
      item_info: {
        author: "Bit Nance",
        image_url:
          "https://photos.pinksale.finance/file/pinksale-logo-upload/1710296072926-3cf4b4f755e324549d433137feae5513.png",
        name: "Token Payment",
        category: "Bit Nance",
      },
    },
    listeners: {
      loaded: () => setCommingSoonDilouge(false),
    },
  };

  const wertWidget = new WertWidget({
    ...signedData,
    ...otherWidgetOptions,
  });

  /// wert configration ends here
  const toLocalFormat = (val) => {
    return val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (event, status) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");

    if (!status) {
      setReceiveToken("");
      setAmount(newValue);
      setReceiveTokenStatus(false);
    } else {
      setAmount("");
      setReceiveToken(newValue);
      setReceiveTokenStatus(true);
    }
  };
  useEffect(() => {
    if (receiveTokenStatus) {
      if (receiveToken && +receiveToken > 0) {
        if (buyWith === "USDT") {
          let tokenUSDT = +receiveToken / +tokenPerUSDT;
          setAmount(fixedDecimal(tokenUSDT));
        } else {
          let tokenETH = +receiveToken / +tokenPerEth;
          setAmount(fixedDecimal(tokenETH));
        }
      } else {
        setAmount("");
      }
    } else {
      if (amount && +amount > 0) {
        if (buyWith === "USDT") {
          let tokenUSDT = +tokenPerUSDT * +amount;
          setReceiveToken(fixedDecimal(tokenUSDT));
        } else {
          let tokenETH = +tokenPerEth * +amount;
          setReceiveToken(fixedDecimal(tokenETH));
        }
      } else {
        setReceiveToken("");
      }
    }
  }, [
    amount,
    buyWith,
    receiveToken,
    receiveTokenStatus,
    tokenPerEth,
    tokenPerUSDT,
  ]);

  const initVoidSigner = async () => {
    try {
      const [
        usdtToTokenResult,
        ethToTokenResult,
        soldBSCResult,
        soldETHResult,
        statusPresaleResult,
        raisedUSDTBSCResult,
        raisedUSDTETHResult,
        tokenPerUsdPrice,
        lastPriceUpdateTime,
        priceIncreaseInterval,
      ] = await Promise.all([
        newPreSaleReadFunction(bscChainId, "usdtToToken", [parseEther("1")]),
        newPreSaleReadFunction(chainId, "nativeToToken", [parseEther("1")]),
        newPreSaleReadFunction(bscChainId, "soldToken"),
        newPreSaleReadFunction(ethChainId, "soldToken"),
        newPreSaleReadFunction(bscChainId, "isPresaleEnded"),
        newPreSaleReadFunction(bscChainId, "amountRaisedOverall"),
        newPreSaleReadFunction(ethChainId, "amountRaisedOverall"),
        newPreSaleReadFunction(bscChainId, "tokenPerUsdPrice"),
        newPreSaleReadFunction(bscChainId, "lastPriceUpdateTime"),
        newPreSaleReadFunction(bscChainId, "priceIncreaseInterval"),
      ]);
      setTimeLastUpdate(
        Number(lastPriceUpdateTime?.toString()) +
          Number(priceIncreaseInterval?.toString())
      );
      const usdtToToken = +formatUnits(usdtToTokenResult?.toString(), 18);
      const ethToToken = +formatUnits(ethToTokenResult?.toString(), 18);
      setTokenPerUSDT(usdtToToken);
      setTokenPerEth(ethToToken);

      const soldBSC = +parseFloat(
        +formatUnits(soldBSCResult?.toString(), 18)
      ).toFixed(0);
      const soldETH = +parseFloat(
        +formatUnits(soldETHResult?.toString(), 18)
      ).toFixed(0);
      const sold = soldBSC + soldETH;
      settotalSoldTokens(sold);

      const statusPresale = statusPresaleResult;
      setisClaimEnabled(statusPresale);

      const raisedUSDTBSC = +parseFloat(
        +formatUnits(raisedUSDTBSCResult?.toString(), 18)
      ).toFixed(0);
      const raisedUSDTETH = +parseFloat(
        +formatUnits(raisedUSDTETHResult?.toString(), 6)
      ).toFixed(0);
      const total = raisedUSDTBSC + raisedUSDTETH;
      settotalRaised(total);

      setTokenPrice(Number(formatUnits(tokenPerUsdPrice.toString(), 18)));
      const progress = (sold / 5600000) * 100;
      setProgressBar(progress);

      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };

  useEffect(() => {
    initVoidSigner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction, chainId]);

  const buyHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!amount || amount <= 0) {
      return showAlert("Error! Please enter amount to buy.");
    }
    try {
      setloading(true);

      if (buyWith === "USDT") {
        const usdtDecimal = await usdtReadFunction(chainId, "decimals");
        await usdtWriteFunction(chainId, "approve", [
          chainId === bscChainId ? newBscPresaleAddress : newEthPresaleAddress,
          parseUnits(amount.toString(), +usdtDecimal?.toString()).toString(),
        ]);
        await newPreSaleWriteFunction(chainId, "buyTokenUSDT", [
          parseUnits(amount.toString(), +usdtDecimal?.toString()).toString(),
        ]);
      } else if (buyWith === "CARD") {
        setCommingSoonDilouge(true);
        wertWidget.open();
      } else {
        await newPreSaleWriteFunction(
          chainId,
          "buyToken",
          [],
          parseUnits(amount.toString(), 18).toString()
        );
      }
      setAmount("");
      setReceiveToken("");
      setCallFunction(true);
      getUserTokenBalance();
      setloading(false);
      if (buyWith !== "CARD") {
        showAlert("Success! Transaction Confirmed", "success");
      }
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };
  const claimHandler = async () => {
    if (account) {
      try {
        setloading(true);
        let dec = await tokenReadFunction("decimals");
        dec = +dec?.toString();

        const bscUserBalance1 = await preSaleReadFunction(bscChainId, "users", [
          account,
        ]);

        const updatedBscUserBalance1 = +parseFloat(
          +formatUnits(bscUserBalance1[2]?.toString(), dec)
        )?.toFixed(2);

        const bscUserBalance2 = await newPreSaleReadFunction(
          bscChainId,
          "users",
          [account]
        );

        const updatedBscUserBalance2 = +parseFloat(
          +formatUnits(bscUserBalance2[2]?.toString(), dec)
        )?.toFixed(2);

        if (+updatedBscUserBalance1 <= 0 && +updatedBscUserBalance2 <= 0) {
          return setAlertState({
            open: true,
            message: `Error! Uou don't have enough tokens to claim.`,
            severity: "error",
          });
        }

        if (+updatedBscUserBalance1 > 0) {
          await preSaleWriteFunction(bscChainId, "claimTokens");
        }
        if (+updatedBscUserBalance2 > 0) {
          await newPreSaleWriteFunction(bscChainId, "claimTokens");
        }

        setCallFunction(true);
        getUserTokenBalance();
        setloading(false);
        setAlertState({
          open: true,
          message: `Transection Completed!`,
          severity: "success",
        });
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    setAmount("");
    setReceiveToken("");
  }, [chainId]);
  return (
    <Box className="borderImg">
      <CommingSoon open={commingSoonDilouge} setOpen={setCommingSoonDilouge} />
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Stack p={{ xs: 2, md: 3 }} gap={{ xs: 2, md: 2 }}>
        <Box
          className="borderImg"
          sx={{
            py: { xs: 2, md: 3 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "800",
              fontSize: "24px",
              lineHeight: "29px",
              color: "#F2F2F2",
              textAlign: "center",
            }}
          >
            BUY $BTN{" "}
            <span
              style={{
                color: "#F0B90B",
              }}
            >
              {" "}
              LISTING DECEMBER 5th
            </span>{" "}
          </Typography>
        </Box>
        <Box>
          <TimerCountDown time={timeLastUpdate} />
        </Box>
        <Stack>
          {/* <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Proxima Nova",
                  fontWeight: "700",
                  fontSize: "12px",
                  color: "#ffffff",
                }}
              >
                GOAL
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Proxima Nova",
                  fontWeight: "700",
                  fontSize: "12px",
                  color: "#ffffff",
                }}
              >
                5,600,000
              </Typography>
            </Stack> */}
          <ProgressBar time={timeLastUpdate} />
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "2px",
              // width: "50%",
              background: "#F0B90B",
              flexGrow: 1,
            }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontSize: { xs: "10px", sm: "13px" },
              textAlign: "center",
              fontWeight: "700",
              color: "#ffff",
              fontFamily: "Montserrat",
              mx: 2,
              // width: "120px",
            }}
          >
            Your Purchased $BTN ={" "}
            {toLocalFormat(parseFloat(userPurchasedTokens)?.toFixed(2))}
          </Typography>

          <Box
            sx={{
              height: "2px",
              flexGrow: 1,
              // width: "50%",
              background: "#F0B90B",
            }}
          />
        </Stack>
        <Stack
          flexDirection={"row"}
          textAlign={"center"}
          justifyContent={"space-around"}
          py={2}
          className="borderImg"
        >
          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#F0B90B",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontWeight: "700",
              }}
            >
              TOKENS SOLD
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                color: "#F2F2F2",
                fontFamily: "Proxima Nova",
                fontSize: "20px",
                fontWeight: "700",
                mt: 1,
              }}
            >
              {totalSoldTokens && Number(totalSoldTokens) > 0 ? (
                toLocalFormat(totalSoldTokens)
              ) : (
                <Skeleton
                  sx={{
                    background: "#F0B90B",
                    width: { xs: "30px", sm: "40px" },
                    height: { xs: "25px", sm: "30px" },
                    margin: "auto",
                  }}
                />
              )}
            </Typography>
          </Stack>

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              background: "#F0B90B",
              width: "2px",
            }}
          />

          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#F0B90B",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontWeight: "700",
              }}
            >
              $USD RAISED
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                color: "#F2F2F2",
                fontFamily: "Proxima Nova",
                fontSize: "20px",
                fontWeight: "700",
                mt: 1,
              }}
            >
              {totalRaised && Number(totalRaised) > 0 ? (
                `$${toLocalFormat(totalRaised)}`
              ) : (
                <Skeleton
                  sx={{
                    background: "#F0B90B",
                    width: { xs: "30px", sm: "40px" },
                    height: { xs: "25px", sm: "30px" },
                    margin: "auto",
                  }}
                />
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "2px",
              // width: "50%",
              background: "#F0B90B",
              flexGrow: 1,
            }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontSize: { xs: "10px", sm: "13px" },
              textAlign: "center",
              fontWeight: "700",
              color: "#ffff",
              fontFamily: "Montserrat",
              mx: 2,
              // width: "120px",
            }}
          >
            1 $BTN = $
            {Number(tokenPrice) > 0
              ? parseFloat(1 / Number(tokenPrice))?.toFixed(4)
              : "0.00"}
          </Typography>

          <Box
            sx={{
              height: "2px",
              flexGrow: 1,
              // width: "50%",
              background: "#F0B90B",
            }}
          />
        </Stack>
        <Stack
          className="borderImg"
          sx={{
            flexDirection: "row",
            justifyContent: "space-around",
            py: 1.5,
            px: { xs: 1, md: 1 },
          }}
        >
          {[
            {
              text: chainId === bscChainId ? "BNB" : "ETH",
              img: chainId === bscChainId ? bnbLogo : ethIcon,
              val: "BNB",
            },
            {
              text: "USDT",
              img: usdtLogo,
              val: "USDT",
            },
            {
              text: "CARD",
              img: cardLogo,
              val: "CARD",
            },
          ].map(({ text, img, val }) => (
            <Stack
              onClick={() => setBuyWith(val)}
              className={buyWith === val ? "borderImg" : ""}
              key={text}
              sx={{
                cursor: "pointer",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                // width: xs"100px",

                py: { xs: 1, sm: 2 },
                px: { xs: 2, md: 4 },
                background: buyWith === val ? "#D9D9D90D" : "transparent",
              }}
            >
              <Box component={"img"} alt="" src={img} width="23px" />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffff",
                  fontFamily: "Proxima Nova",
                  fontSize: { xs: "11px", sm: "13px" },
                  fontWeight: "700",
                  mt: 0.4,
                }}
              >
                {text}
              </Typography>
            </Stack>
          ))}
        </Stack>{" "}
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack gap={0.5} pr={2}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "11px", sm: "13px" },
                fontFamily: "Proxima Nova",
                fontWeight: "700",
                ml: 1,
              }}
            >
              YOU PAY
            </Typography>
            <Box>
              <StyledInput
                type="text"
                placeholder="0.00"
                value={amount}
                onChange={(e) => handleInputChange(e, false)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={
                          buyWith === "BNB"
                            ? chainId === bscChainId
                              ? bnbLogo
                              : ethIcon
                            : buyWith === "USDT"
                            ? usdtLogo
                            : cardLogo
                        }
                        sx={{
                          width: { xs: "23px", md: "25px" },
                          marginRight: { xs: "6px", md: "8px" },
                          marginLeft: { xs: "-10px", md: "0px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Box
            component={"img"}
            alt=""
            src={inputCenterLogo}
            width="40px"
            height={"40px"}
          />
          <Stack gap={0.5} pl={2}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "11px", sm: "13px" },
                fontFamily: "Proxima Nova",
                fontWeight: "700",
                ml: 1,
              }}
            >
              YOU GET
            </Typography>
            <Box>
              <StyledInput
                type="text"
                placeholder="0.00"
                // value={amount && +amount > 0 ? receiveToken : 0}
                value={receiveToken}
                onChange={(e) => handleInputChange(e, true)}
                InputProps={{
                  // readOnly: true,
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={tokenLogo}
                        sx={{
                          width: { xs: "23px", md: "25px" },
                          marginRight: { xs: "6px", md: "8px" },
                          marginLeft: { xs: "-10px", md: "0px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            gap: { xs: 2, sm: 3 },
          }}
        >
          {isClaimEnabled ? (
            <StyledButtonTwo
              width="50%"
              fontSize={{ xs: "15px", sm: "18px" }}
              onClick={async () => claimHandler()}
            >
              Claim
            </StyledButtonTwo>
          ) : (
            <StyledButtonTwo
              width="50%"
              fontSize={{ xs: "15px", sm: "18px" }}
              onClick={async () => (account ? buyHandler() : await open())}
            >
              {" "}
              {account ? "BUY" : matches ? "CONNECT" : "CONNECT WALLET"}
            </StyledButtonTwo>
          )}
          <StyledButtonTwo
            width="50%"
            fontSize={{ xs: "15px", sm: "18px" }}
            onClick={() => {
              switchChain({
                chainId: chainId === bscChainId ? ethChainId : bscChainId,
              });
            }}
          >
            {" "}
            {chainId === bscChainId ? "BUY WITH ETH" : "BUY WITH BNB"}
          </StyledButtonTwo>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Presale;
