import tokenAbi from "./tokenAbi.json";
import ethPresaleAbi from "./ethPresaleAbi.json";
import bscPresaleAbi from "./bscPresaleAbi.json";
import usdtAbi from "./usdtAbi.json";
import newEthPresaleAbi from "./newEthPresaleAbi.json";
import newBscPresaleAbi from "./newBscPresaleAbi.json";
import {
  tokenAddress,
  bscPresaleAddress,
  bscUsdtAddress,
  ethPresaleAddress,
  ethUsdtAddress,
  newEthPresaleAddress,
  newBscPresaleAddress,
} from "./environment";

import {
  readContract,
  writeContract,
  waitForTransactionReceipt,
} from "@wagmi/core";
import { config } from "../Web3Provider";

// const chainId = 56;

export const bscChainId = 56;
export const ethChainId = 1;

export const tokenReadFunction = async (functionName, args) => {
  const data = await readContract(config, {
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    chainId: 56,
    args,
  });
  return data;
};

export const preSaleReadFunction = async (chainId, functionName, args) => {
  const data = await readContract(config, {
    address: chainId === bscChainId ? bscPresaleAddress : ethPresaleAddress,
    abi: chainId === bscChainId ? bscPresaleAbi : ethPresaleAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
  });
  return data;
};

export const newPreSaleReadFunction = async (chainId, functionName, args) => {
  const data = await readContract(config, {
    address:
      chainId === bscChainId ? newBscPresaleAddress : newEthPresaleAddress,
    abi: chainId === bscChainId ? newBscPresaleAbi : newEthPresaleAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
  });
  return data;
};

export const usdtReadFunction = async (chainId, functionName, args) => {
  const data = await readContract(config, {
    address: chainId === bscChainId ? bscUsdtAddress : ethUsdtAddress,
    abi: usdtAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
  });
  return data;
};

/// write functions
export const tokenWriteFunction = async (functionName, args) => {
  const hash = await writeContract(config, {
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    chainId: 56,
    args,
  });
  const receipt = await waitForTransactionReceipt(config, { hash });
  return receipt?.transactionHash;
};

export const preSaleWriteFunction = async (
  chainId,
  functionName,
  args,
  value
) => {
  const hash = await writeContract(config, {
    address: chainId === bscChainId ? bscPresaleAddress : ethPresaleAddress,
    abi: chainId === bscChainId ? bscPresaleAbi : ethPresaleAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
    value,
  });
  const receipt = await waitForTransactionReceipt(config, { hash });
  return receipt?.transactionHash;
};

export const newPreSaleWriteFunction = async (
  chainId,
  functionName,
  args,
  value
) => {
  const hash = await writeContract(config, {
    address:
      chainId === bscChainId ? newBscPresaleAddress : newEthPresaleAddress,
    abi: chainId === bscChainId ? newBscPresaleAbi : newEthPresaleAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
    value,
  });
  const receipt = await waitForTransactionReceipt(config, { hash });
  return receipt?.transactionHash;
};

export const usdtWriteFunction = async (chainId, functionName, args) => {
  const hash = await writeContract(config, {
    address: chainId === bscChainId ? bscUsdtAddress : ethUsdtAddress,
    abi: usdtAbi,
    functionName,
    chainId: chainId === bscChainId ? bscChainId : ethChainId,
    args,
  });
  const receipt = await waitForTransactionReceipt(config, { hash });
  return receipt?.transactionHash;
};
