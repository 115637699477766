import { useContext, useState, useEffect, useCallback } from "react";
import { useAccount } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import { Route, Routes } from "react-router-dom";
import {
  AddressSection,
  Community,
  HeroSection,
  Introduction,
  Roadmap,
  Toknomics,
} from "./pages";
import Footer from "./components/Footer";
import {
  bscChainId,
  ethChainId,
  newPreSaleReadFunction,
  preSaleReadFunction,
  tokenReadFunction,
} from "./ConnectivityAssets/hooks";
import { formatUnits } from "viem";

function App() {
  const { account, chainIdArray, setUserPurchasedTokens } =
    useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chainId } = useAccount();

  useEffect(() => {
    if (account && chainId && !chainIdArray.includes(chainId)) {
      setOpenNetworkSwitch(true);
    }
  }, [account, chainIdArray, chainId]);

  const getUserTokenBalance = useCallback(async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      dec = +dec?.toString();

      let firstAmt = 0;

      //bsc
      const bscUserExsit1 = await preSaleReadFunction(bscChainId, "isExist", [
        account,
      ]);

      const bscUserExsit2 = await newPreSaleReadFunction(
        bscChainId,
        "isExist",
        [account]
      );

      ///eth

      const ethUserExsit1 = await preSaleReadFunction(ethChainId, "isExist", [
        account,
      ]);

      const ethUserExsit2 = await newPreSaleReadFunction(
        ethChainId,
        "isExist",
        [account]
      );

      if (bscUserExsit1) {
        let userData = await preSaleReadFunction(bscChainId, "users", [
          account,
        ]);
        firstAmt += +parseFloat(
          +formatUnits(userData[2]?.toString(), dec)
        )?.toFixed(2);
      }
      if (bscUserExsit2) {
        let userData = await newPreSaleReadFunction(bscChainId, "users", [
          account,
        ]);

        firstAmt += +parseFloat(
          +formatUnits(userData[2]?.toString(), dec)
        )?.toFixed(2);
      }

      if (ethUserExsit1) {
        let userData = await preSaleReadFunction(ethChainId, "users", [
          account,
        ]);
        firstAmt += +parseFloat(
          +formatUnits(userData[2]?.toString(), dec)
        )?.toFixed(2);
      }

      if (ethUserExsit2) {
        let userData = await newPreSaleReadFunction(ethChainId, "users", [
          account,
        ]);
        firstAmt += +parseFloat(
          +formatUnits(userData[2]?.toString(), dec)
        )?.toFixed(2);
      }

      setUserPurchasedTokens(+firstAmt);
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account) {
      getUserTokenBalance();
    }
  }, [account, getUserTokenBalance]);

  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection getUserTokenBalance={getUserTokenBalance} />
              <AddressSection />
              <Introduction />
              <Roadmap />
              <Toknomics />
              <Community />
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
