import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { bsc, mainnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConectivityProvider } from "./utils";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

const projectId = "cba73ada547c01c1a64d7725fb732495";

const chains = [bsc, mainnet];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  auth: {
    email: false, // default to true
  },
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: "dark",
  allowUnsupportedChain: true,
  enableOnramp: false,
});

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConectivityProvider>
          <BrowserRouter>{children} </BrowserRouter>
        </ConectivityProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
