// export const tokenAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
// export const preSaleAddress = "0x57B610840D19DFFb6C30dbea5f06D041d98E4D76";
// export const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";

///bsc

export const tokenAddress = "0xbcf5DaaD2FBF7a8d33b901684DFD6FdeA69C79F8";
export const bscPresaleAddress = "0x57B610840D19DFFb6C30dbea5f06D041d98E4D76";
export const bscUsdtAddress = "0x55d398326f99059fF775485246999027B3197955";
export const newBscPresaleAddress =
  "0xBd7Ebdc1725e0673dCC71b7255B0C35c9dD1d096";

//eth

export const ethPresaleAddress = "0x2a52A93D030b068AD3BA70a946B6B176c6EB7d9f";
export const ethUsdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const newEthPresaleAddress =
  "0xe17b39FFBc7cD9e7ee6411E2c0825f8c642215d7";
